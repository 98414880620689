import axios from "@/plugins/axios/axios";
import {
  CompanyClient,
  CompanyFinancial,
  CompanyFinancialBody,
  FinancialAttachment,
  UserTeamRequest,
  WebCustomerRequestFilter,
  WebCustomerStats,
  WebCustomerTableUser,
} from "@/shared/types/admin";
import { Contact } from "@/shared/types/filters";
import { UserTeam } from "@/shared/types/common";
import { AxiosResponse } from "axios";

const getWebCustomerList = (
  filter: WebCustomerRequestFilter,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    items: WebCustomerTableUser[];
    count: number;
  }>
> => {
  return axios.post("/webcustomers/filter", filter, {
    signal: abortSignal,
  });
};

const getWebCustomerStats = (
  filter: {
    webCustomerID: number;
    startDate: string;
  },
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<WebCustomerStats>> => {
  return axios.post("/webcustomers/stats", filter, {
    signal: abortSignal,
  });
};

const webCustomerSignIn = (
  webCustomerID: number,
): Promise<AxiosResponse<boolean>> => {
  return axios.get(`/webcustomers/loginAs/${webCustomerID}`);
};

const disableWebCustomer = (id: number): Promise<AxiosResponse<boolean>> => {
  return axios.put(`/webcustomers/disable/${id}`);
};

const updateWebCustomer = (
  id: number,
  data: WebCustomerTableUser,
): Promise<AxiosResponse<WebCustomerTableUser>> => {
  return axios.put(`/webcustomers/${id}`, data);
};

const createWebCustomer = (
  data: WebCustomerTableUser,
): Promise<AxiosResponse<WebCustomerTableUser>> => {
  return axios.post("/webcustomers", data);
};

const getUserTeams = (
  user: UserTeamRequest,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    count: number;
    items: UserTeam[];
  }>
> => {
  return axios.post("/teams/filter", user, {
    signal: abortSignal,
  });
};

const updateTeam = (user: UserTeam): Promise<AxiosResponse<UserTeam>> => {
  return axios.put(`/teams/${user.id}`, user);
};

const deleteTeam = (id: number): Promise<AxiosResponse<boolean>> => {
  return axios.delete(`/teams/${id}`);
};

const createTeam = (team: UserTeam): Promise<AxiosResponse<UserTeam>> => {
  return axios.post("/teams", team);
};

function getCompanyClients(
  clientID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyClient[]>> {
  return axios.get(`/companyClients/${clientID}`, {
    signal: abortSignal,
  });
}

function createCompanyClient(
  data: CompanyClient,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyClient>> {
  return axios.post("/companyClients", data, {
    signal: abortSignal,
  });
}

function updateCompanyClient(
  companyClientID: number,
  data: CompanyClient,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyClient>> {
  return axios.put(`/companyClients/${companyClientID}`, data, {
    signal: abortSignal,
  });
}

function deleteCompanyClient(
  companyClientID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/companyClients/${companyClientID}`, {
    signal: abortSignal,
  });
}

function addContact(
  data: Contact,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Contact>> {
  return axios.post("/contacts/company/add", data, {
    signal: abortSignal,
  });
}

function editContact(
  data: Contact,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Contact>> {
  return axios.put(`/contacts/company/${data.id}`, data, {
    signal: abortSignal,
  });
}

function deleteContact(
  contactID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/contacts/company/${contactID}`, {
    signal: abortSignal,
  });
}
const reviewMarkInvestor = (
  requestId: number,
): Promise<AxiosResponse<string>> => {
  return axios.put(`/review/convert/${requestId}`);
};

function getCompanyFinancials(
  clientID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyFinancial[]>> {
  return axios.get(`/financials/${clientID}`, {
    signal: abortSignal,
  });
}

function deleteFinancials(
  financialID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/financials/${financialID}`, {
    signal: abortSignal,
  });
}

function createFinancials(
  data: CompanyFinancialBody,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyFinancial>> {
  return axios.post("/financials", data, {
    signal: abortSignal,
  });
}

function updateFinancials(
  data: CompanyFinancialBody,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyFinancial>> {
  return axios.put(`/financials/${data.id}`, data, {
    signal: abortSignal,
  });
}

function attachFinancialDocument(
  financialID: number,
  file: File,
  labelIDs: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<FinancialAttachment>> {
  return axios.post(
    `/financials/documents/${financialID}?labelIDs=${labelIDs.join(",")}`,
    { file },
    {
      signal: abortSignal,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

function deleteFinancialAttachment(
  attachmentID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/financials/documents/${attachmentID}`, {
    signal: abortSignal,
  });
}

function deleteFinancialAttachmentLabel(
  attachmentID: number,
  labelID: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(
    `/financials/documents/detachLabels/${attachmentID}/${labelID}`,
    {
      signal: abortSignal,
    },
  );
}

function uploadCompanyClientsLogo(
  companyClientId: number,
  file: File,
): Promise<AxiosResponse<string>> {
  return axios.post(
    `/companyClients/logo/${companyClientId}`,
    { file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

function removeCompanyClientsLogo(
  companyClientId: number,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/companyClients/logo/${companyClientId}`);
}

export default {
  getWebCustomerList,
  getWebCustomerStats,
  webCustomerSignIn,
  disableWebCustomer,
  updateWebCustomer,
  createWebCustomer,
  getUserTeams,
  updateTeam,
  deleteTeam,
  createTeam,
  getCompanyClients,
  createCompanyClient,
  deleteCompanyClient,
  updateCompanyClient,
  addContact,
  editContact,
  deleteContact,
  reviewMarkInvestor,
  getCompanyFinancials,
  deleteFinancials,
  createFinancials,
  updateFinancials,
  attachFinancialDocument,
  deleteFinancialAttachment,
  deleteFinancialAttachmentLabel,
  uploadCompanyClientsLogo,
  removeCompanyClientsLogo,
};
