<template>
  <div class="company-info-section-item">
    <div
      :class="[
        'company-info-section-item__section',
        { 'company-info-section-item__section--center': isAlignCenter },
        { 'company-info-section-item__section--column': isColumn },
      ]"
    >
      <div class="company-info-section-item__section__container">
        <h6
          :class="[
            'company-info-section-item__section__title',
            { 'required-field': isRequired },
          ]"
        >
          <slot name="title" />
        </h6>
        <AppInformation v-if="information" :content="information" />
      </div>
      <div class="company-info-section-item__section__content">
        <slot name="content" />
      </div>
    </div>

    <div class="company-info-section-item__section">
      <div class="company-info-section-item__section__container" />
      <div class="company-info-section-item__section__content">
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AppInformation from "@/components/app/AppInformation/AppInformation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "CompanyInfoSectionItem",

  components: {
    AppInformation,
  },

  props: {
    isAlignCenter: {
      type: Boolean,
      default: false,
    },

    information: String,

    isRequired: Boolean,

    width: {
      type: String,
      default: "180px",
    },

    contentMaxWidth: {
      type: String,
      default: "85%",
    },

    gap: {
      type: String,
      default: "50px",
    },

    headerWidth: String,

    isColumn: Boolean,
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.company-info-section-item {
  &__section {
    display: flex;
    gap: v-bind(gap);
    align-items: baseline;

    &__container {
      display: flex;
      gap: rem(2px);
      flex-shrink: 0;
      margin: 0;
      text-align: right;
      width: v-bind(width);
      justify-content: flex-end;
    }

    &--center {
      align-items: center;
    }

    &--column {
      flex-direction: column;

      .company-info-section-item__section__container {
        justify-content: flex-start;
      }
    }

    &__title {
      font-size: rem(12px);
      font-weight: 700;
      margin: 0;
      color: $coreBlack;
      width: v-bind(headerWidth);
      text-align: left;
    }

    &__content {
      flex-grow: 1;
      max-width: v-bind(contentMaxWidth);
      width: 100%;
    }
  }
}
</style>
