<template>
  <div class="app-layout">
    <AppExpiryBanner v-if="webCustomerStore.showSubscriptionBanner" />
    <header class="app-layout__navigation-bar">
      <AppLink
        to="/dashboard"
        link-component="router-link"
        class="app-layout__navigation-bar__brand"
      >
        <img
          :src="netZeroInsightsLogoHighRes"
          alt="Net Zero Insights"
          class="app-layout__navigation-bar__brand__logo"
        />
      </AppLink>
      <form @submit.prevent autocomplete="off">
        <AppInput
          :leading="magnifierGrey"
          placeholder="Search..."
          autocomplete="off"
          width="400px"
          v-model.trim="searchResultsText"
        />
      </form>
      <div class="app-layout__navigation-bar__actions">
        <AppButton
          v-if="webCustomer.profileClaimToken"
          label="Edit company profile"
          size="small"
          :to="claimProfileURL"
          font-size="12px"
          height="30px"
        />
        <AppButton
          v-if="webCustomer.preview"
          href="https://meetings-eu1.hubspot.com/federico-cristoforoni"
          label="Upgrade"
          size="small"
          font-size="12px"
          height="30px"
          class="app-layout__navigation-bar__actions__upgrade"
        />
        <AppTooltip content="Add an organisation to the Net0 Platform">
          <AppButton
            :icon="plusIconGreen"
            type="empty"
            padding="0"
            @click="handleAddCompanyClick"
          />
        </AppTooltip>

        <AppNewCompanyModal
          v-model="showAddCompanyModal"
          :is-loading="isLoading"
          :name="searchText"
          @add="handleAddNewCompany"
        />
        <AppFloatingMenu
          v-model="appFloatingMenuModel"
          :options="appFloatingMenuOptions"
          side="right"
        >
          <template #content>
            <div
              class="app-layout__navigation-bar__actions__user"
              @click="handleAppFloatingMenuClick"
            >
              {{ userInitials }}
            </div>
          </template>
        </AppFloatingMenu>
      </div>
    </header>
    <main class="app-layout__body">
      <AppSidebar :links="links" />
      <div
        class="app-layout__body__content"
        ref="app-content"
        :style="contentStyle"
      >
        <div class="app-layout__body__content__header-line"></div>
        <slot />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import { defineComponent, StyleValue } from "vue";
import {
  menuBlack,
  netZeroInsightsLogoHighRes,
  dashboardBlack,
  dashboardGreen,
  hearthGreen,
  hearthBlack,
  savedGreen,
  savedBlack,
  settingsGreen,
  settingsBlack,
  magnifierGrey,
  plusIconGreen,
  searchBlack,
  searchGreen,
  editGreen,
  editBlack,
  adminBlack,
  adminGreen,
  medalBlack,
  taxonomyBlack,
  taxonomyGreen,
  marketResearch,
  marketResearchGreen,
} from "@/shared/constants/icons";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import {
  AppFloatingMenuOption,
  AppSidebarLink,
} from "@/shared/types/components";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import { WebCustomer } from "@/shared/types/common";
import AppSidebar from "@/components/app/AppSidebar/AppSidebar.vue";
import { mapStores, storeToRefs } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import AppNewCompanyModal from "@/components/dashboard/AppNewCompanyModal/AppNewCompanyModal.vue";
import { NewCompanyModel } from "@/shared/types/organization";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import { AxiosError } from "axios";
import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import { ClaimProfileSteps } from "@/shared/types/profileClaim";
import { ADMIN_PANEL_TABS } from "@/shared/types/admin";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";

export default defineComponent({
  name: "AppLayout",

  components: {
    AppInput,
    AppFloatingMenu,
    AppSidebar,
    AppButton,
    AppNewCompanyModal,
    AppTooltip,
    AppExpiryBanner,
    AppLink,
  },

  setup() {
    const { searchResultsText } = useSearchResults();
    const { getNewTabEnabled } = storeToRefs(useWebCustomerStore());

    return { searchResultsText, getNewTabEnabled };
  },

  data() {
    return {
      appFloatingMenuModel: false,
      netZeroInsightsLogoHighRes,
      magnifierGrey,
      showAddCompanyModal: false,
      searchText: "",
      isLoading: false,
      plusIconGreen,
      abortController: undefined as AbortController | undefined,
      navbarHeight: 64,
      contentPaddingTop: "112px",
      headerLineTopPosition: `${this.navbarHeight}px`,
      contentPaddingTopForSpecificPages: `${this.navbarHeight}px`,
      navbarTopPosition: "0",
      expiryBannerHeight: 32,
    };
  },

  methods: {
    handleAppFloatingMenuClick() {
      this.appFloatingMenuModel = !this.appFloatingMenuModel;
    },

    handleDisplayCompanyModal() {
      this.showAddCompanyModal = true;
    },

    handleAddCompanyClick() {
      this.handleDisplayCompanyModal();
    },

    async handleAddNewCompany(newCompany: NewCompanyModel) {
      try {
        this.isLoading = true;
        const newAddedCompany =
          await organizationsService.addNewCompany(newCompany);

        if (this.webCustomerStore.isUserAdmin) {
          if (this.getNewTabEnabled) {
            window.open(
              `/organization/${newAddedCompany.data.directURL}`,
              "_blank",
            );
          } else {
            this.$router.push(
              `/organization/${newAddedCompany.data.directURL}`,
            );
          }
        }

        this.showAddCompanyModal = false;
      } catch (e) {
        const errorMessage =
          (e as AxiosError).response?.status === 400
            ? "Invalid URL"
            : defaultServerErrorMessage;

        this.notificationsStore.notify(errorMessage, "danger", 3000);
      } finally {
        this.isLoading = false;
      }
    },

    goSearchResultsPage() {
      if (this.$route.name !== "SearchResultsView" && this.searchResultsText) {
        this.$router.push({
          name: "SearchResultsView",
          params: {
            tab: "all",
            keyword: this.searchResultsText,
          },
        });
      }
    },
  },

  computed: {
    ...mapStores(useWebCustomerStore, useNotificationsStore),

    webCustomer(): WebCustomer {
      return this.webCustomerStore.webCustomer;
    },

    contentStyle(): StyleValue {
      const path = this.$route.path as string;
      const name = this.$route.name as string;

      if ((this.$route.name as string) === "DeepDiveDetailsView") {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingLeft: 0,
          paddingBottom: 0,
        };
      } else if (
        [
          "OrganizationDetails",
          "OrganisationDetails",
          "InvestorDetails",
        ].includes(name)
      ) {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingLeft: 0,
        };
      } else if (
        name === "Portfolio" ||
        path.includes("/organizations") ||
        path.includes("/funding-rounds") ||
        (path.includes("/search") && !path.includes("/searches")) ||
        path === "/investors" ||
        path.includes("/admin-panel") ||
        path.includes("/similar-companies")
      ) {
        return {
          paddingTop: this.contentPaddingTopForSpecificPages,
          paddingBottom: 0,
        };
      } else if (
        ["/lists/organisations", "/lists/investors", "/searches"].includes(path)
      ) {
        return {
          overflowY: "scroll",
        };
      } else {
        return {};
      }
    },

    userInitials(): string {
      return this.webCustomer &&
        this.webCustomer.name &&
        this.webCustomer.surname
        ? `${this.webCustomer.name[0]}${this.webCustomer.surname[0]}`.toUpperCase()
        : "";
    },

    appFloatingMenuOptions(): AppFloatingMenuOption[] {
      return [
        {
          name: "extendSubscription",
          label: "Extend subscription",
          link: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
          isSelected: ![
            "/user/profile",
            "/lists",
            "/searches",
            "/admin-panel",
          ].includes(this.$route.path),
        },
        {
          name: "account",
          label: "Account",
          link: "/user/profile",
          isSelected: this.$route.path === "/user/profile",
        },
        ...(this.webCustomerStore.isUserAdmin
          ? [
              {
                label: "Admin panel",
                link: "/admin-panel",
                name: "admin-panel",
                isSelected: this.$route.path === "/admin-panel",
              },
            ]
          : []),
        {
          name: "portfolios",
          label: "Portfolios",
          link: "/lists",
          isSelected: this.$route.path === "/lists",
        },
        {
          name: "savedSearches",
          label: "Saved searches",
          link: "/searches",
          isSelected: this.$route.path === "/searches",
        },
        ...(this.webCustomer.profileClaimToken
          ? [
              {
                name: "editProfile",
                label: "Edit company profile",
                link: this.claimProfileURL,
                isSelected:
                  this.$route.name === "OrganizationDetails" &&
                  this.webCustomer.profileClaimClientID !== undefined &&
                  this.$route.path.includes(
                    this.webCustomer.profileClaimClientID.toString(),
                  ),
              },
            ]
          : []),
        {
          name: "logOut",
          label: "Log out",
          link: "/logout",
        },
      ];
    },

    links(): AppSidebarLink[] {
      return [
        { icon: menuBlack, name: "Menu" },
        {
          icon: dashboardBlack,
          activeIcon: dashboardGreen,
          name: "Dashboard",
          path: "/dashboard",
        },
        {
          icon: searchBlack,
          activeIcon: searchGreen,
          name: "Search",
          path: "/search/",
          children: [
            {
              name: "Recently raised",
              path: "/search/recently-raised",
            },
            {
              name: "Recently viewed",
              path: "/search/recently-viewed",
            },
            {
              name: "Top viewed",
              path: "/search/top-viewed",
            },
            {
              name: "Recent deals",
              path: "/search/recent-deals",
            },
            {
              name: "Largest deals",
              path: "/search/largest-deals",
            },
            {
              name: "Exits",
              path: "/search/exit-deals",
            },
          ],
        },
        {
          icon: taxonomyBlack,
          activeIcon: taxonomyGreen,
          name: "Taxonomy",
          path: "/taxonomy",
        },
        {
          icon: marketResearch,
          activeIcon: marketResearchGreen,
          name: "Market Research",
          path: "/market-research",
        },
        {
          icon: hearthBlack,
          activeIcon: hearthGreen,
          name: "Lists",
          path: "/lists",
          children: [
            {
              name: "Organisations",
              path: "/lists/organisations",
            },
            {
              name: "Investors",
              path: "/lists/investors",
            },
          ],
        },
        {
          icon: savedBlack,
          activeIcon: savedGreen,
          name: "My searches",
          path: "/searches",
        },
        ...(this.webCustomer.freelanceStackRewardCoupon
          ? [
              {
                icon: medalBlack,
                activeIcon: medalBlack,
                name: "Rewards & discounts",
                path: this.webCustomer.freelanceStackRewardCoupon,
                isExternal: true,
              },
            ]
          : []),
        {
          icon: settingsBlack,
          activeIcon: settingsGreen,
          name: "Settings",
          path: "/user/profile",
        },
        ...(this.webCustomer.profileClaimToken
          ? [
              {
                icon: editBlack,
                activeIcon: editGreen,
                name: "Edit company profile",
                path: this.claimProfileURL,
              },
            ]
          : []),
        ...(this.webCustomerStore.isUserAdmin
          ? [
              {
                icon: adminBlack,
                activeIcon: adminGreen,
                name: "Admin panel",
                path: "/admin-panel",
                children: [
                  {
                    name: "Startups Submission",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.STARTUP_SUBMISSION}`,
                  },
                  {
                    name: "Taxonomy Tags",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TAGS}`,
                  },
                  {
                    name: "Taxonomy Tags Types",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_TYPES}`,
                  },
                  {
                    name: "Taxonomy Tags Families",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_FAMILIES}`,
                  },
                  {
                    name: "Taxonomy Items",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_ITEMS}`,
                  },
                  {
                    name: "Taxonomy Relations",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.TAXONOMY_RELATIONS}`,
                  },
                  {
                    name: "Web Customers",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.WEB_CUSTOMERS}`,
                  },
                  {
                    name: "Market Reports",
                    path: `/admin-panel/${ADMIN_PANEL_TABS.MARKET_REPORT}`,
                  },
                ],
              },
            ]
          : []),
      ];
    },

    claimProfileURL(): string {
      return `/claim-profile/${this.webCustomer.profileClaimToken}/${ClaimProfileSteps.BASIC_INFORMATION}`;
    },
  },

  watch: {
    $route() {
      const appContent = this.$refs["app-content"] as HTMLDivElement;

      if (appContent) {
        appContent.scrollTop = 0;
      }
    },

    searchResultsText: "goSearchResultsPage",

    "webCustomerStore.showSubscriptionBanner": {
      immediate: true,
      handler(showSubscriptionBanner) {
        if (showSubscriptionBanner) {
          this.navbarTopPosition = `${this.expiryBannerHeight}px`;
          this.headerLineTopPosition = `${this.navbarHeight + this.expiryBannerHeight}px`;
          this.contentPaddingTop = `${112 + this.expiryBannerHeight}px`;
        } else {
          this.navbarTopPosition = "0";
          this.headerLineTopPosition = `${this.navbarHeight}px`;
          this.contentPaddingTop = "112px";
        }
        this.contentPaddingTopForSpecificPages = this.headerLineTopPosition;
      },
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "@/styles/functions.scss";

.app-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.app-layout__navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: rem(16px) rem(48px);
  height: rem(64px);
  width: 100%;
  position: fixed;
  top: v-bind(navbarTopPosition);
  z-index: 3;
  background-color: $white;
  box-sizing: border-box;
}

.app-layout__navigation-bar__brand {
  display: flex;
  align-items: center;
}

.app-layout__navigation-bar__brand__logo {
  width: rem(100px);
  height: auto;
}

.app-layout__navigation-bar__actions {
  display: flex;
  align-items: center;
  gap: rem(16px);
}

.app-layout__navigation-bar__actions__upgrade {
  margin-right: rem(8px);
}

.app-layout__navigation-bar__actions__user {
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid $borderAlternative;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: rem(32px);
  box-sizing: border-box;
  font-size: rem(14px);
  letter-spacing: rem(1px);
}

.app-layout__navigation-bar__actions__user:hover {
  border-color: $primary;
}

.app-layout__body {
  display: flex;
  flex-grow: 1;
  height: 100vh;
  padding-left: rem(48px);
  position: relative;
}

.app-layout__body__content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  background-color: $background;
  padding: rem(48px);
  padding-top: v-bind(contentPaddingTop);
  flex-grow: 1;
  border-top: 1px solid $border;
  border-left: 1px solid $border;

  &__header-line {
    position: fixed;
    top: v-bind(headerLineTopPosition);
    min-height: rem(1px);
    margin-left: rem(-48px);
    margin-right: rem(-48px);
    width: 100%;
    background: $border;
  }
}
</style>
