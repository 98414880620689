const getPath = (image: string): string =>
  require(`@/assets/images/icons/${image}.png`);

const arrowRight = getPath("4.Right_Arrow");
const arrowRightGreen = getPath("4.Right_Arrow_Green");
const arrowLeft = getPath("4.Left_Arrow");
const arrowLeftGreen = getPath("4.Left_Arrow_Green");
const magnifierGrey = getPath("0.Magnifier_Grey");
const netZeroInsightsLogoHighRes = getPath("0.Net_Zero_Insights_Logo_High_Res");
const netZeroInsightsLogo = getPath("0.Net_Zero_Insights");
const chevronDown = getPath("5.Chevron_Down");
const sortAsc = getPath("5.Sort_Asc");
const sortDesc = getPath("5.Sort_Desc");
const sortInactive = getPath("5.Sort_Inactive");
const information = getPath("5.Information_Icon");
const informationGreen = getPath("5.Information-green");
const showPassword = getPath("4.Show_Password_Icon");
const hidePassword = getPath("4.Hide_Password_Icon");
const cross = getPath("2.Cross_Icon");
const notificationBellGreen = getPath("6.Notification_Bell_Green");
const removeRed = getPath("4.Remove_Icon_Red");
const validate = getPath("4.Validate_Green");
const airPlane = getPath("5.Airplane_Lightgray");
const passwordChanged = getPath("5.Password_Changed");
const defaultIcon = getPath("5.Default_Icon");
const emptyFolder = getPath("4.Empty_Folder");
const openLinkGreen = getPath("5.Open_Link_Green");
const menuBlack = getPath("6.Menu_Black");
const dashboardBlack = getPath("6.Dashboard_Black");
const dashboardGreen = getPath("6.Dashboard_Green");
const organizationsBlack = getPath("6.Organizations_Black");
const organizationsGreen = getPath("6.Organizations_Green");
const organizationsGrey = getPath("6.Organizations_Grey");
const hearthBlack = getPath("0.Hearth_Black");
const hearthWhite = getPath("2.Hearth_White");
const hearthGreen = getPath("0.Hearth_Green");
const savedBlack = getPath("6.Saved_Black");
const savedGreen = getPath("6.Saved_Green");
const lensGreen = getPath("5.Lens_Green");
const moneyBagBlack = getPath("0.Money_Bag_Black");
const moneyBagGrey = getPath("0.Money_Bag_Grey");
const moneyBagGreen = getPath("0.Money_Bag_Green");
const compassBlack = getPath("compass-black");
const compassGreen = getPath("compass-green");
const settingsBlack = getPath("6.Settings_Black");
const settingsGreen = getPath("6.Settings_Green");
const settingsGrey = getPath("6.Settings_Grey");
const robotGreen = getPath("0.robot_green");
const addFriendGreen = getPath("0.add-friend_green");
const editGreen = getPath("2.Edit_Green");
const editWhite = getPath("2.Edit_White");
const editBlack = getPath("2.Edit_Black");
const website = getPath("2.Website");
const question = getPath("2.question");
const location = getPath("2.Location");
const linkedInNormal = getPath("6.linkedin");
const linkedInHover = getPath("6.linkedin_green");
const twitterNormal = getPath("6.twitter");
const twitterHover = getPath("6.twitter_green");
const facebookNormal = getPath("6.facebook");
const facebookHover = getPath("6.facebook_green");
const emailNormal = getPath("6.email");
const emailHover = getPath("6.email_green");
const phoneNormal = getPath("6.call");
const phoneHover = getPath("6.call_green");
const checkedCircle = getPath("6.checked_circle");
const heartFilledGrey = getPath("1.HearthFilled_Grey");
const heartFilledWhite = getPath("2.HearthFilled_White");
const heartFilledGreen = getPath("2.HearthFilled_Green");
const heartFilledRed = getPath("1.HearthFilled_Red");
const craneLogo = getPath("5.CRANE_Logo");
const linkedin = getPath("2.LinkedIn_Normal");
const download = getPath("1.Download_Table_Normal");
const downloadHover = getPath("1.Download_Table_Hover");
const sortColumns = getPath("1.Sort_Columns_Normal");
const sortColumnsHover = getPath("1.Sort_Columns_Hover");
const dragIcon = getPath("5.Drag_Icon");
const crossIcon = getPath("5.Cross_Icon");
const minusIcon = getPath("5.Minus_Icon");
const plusIcon = getPath("4.Plus_Icon_White");
const plusIconGreen = getPath("4.Plus_Icon_Green");
const plusIconGray = getPath("4.Plus_Icon_Gray");
const filterGreen = getPath("1.Filter_Green");
const lastSeenEye = getPath("4.Last_Seen_Icon");
const emailConfirmed = getPath("5.Email_Confirmed");
const removeTagGreen = getPath("4.Remove_Tag_Green");
const icon404 = getPath("404");
const affinityColored = getPath("6.affinity_colored");
const affinityGreen = getPath("6.affinity_green");
const affinityGrey = getPath("6.affinity_grey");
const chevronDownGreen = getPath("5.down-chevron-green");
const refreshIcon = getPath("5.Refresh");
const chainIcon = getPath("7.chain");
const chainIconGreen = getPath("7.chain_green");
const newDownload = getPath("7.download");
const newDownloadGreen = getPath("7.download_green");
const bookmark = getPath("7.bookmark");
const bookmarkGreen = getPath("7.bookmark_green");
const column = getPath("7.column");
const columnGreen = getPath("7.column_green");
const manageColumns = getPath("7.manage-columns");
const manageColumnsGreen = getPath("7.manage-columns_green");
const addToPlayList = getPath("7.add-to-playlist");
const addToPlayListGreen = getPath("7.add-to-playlist_green");
const downloadChart = getPath("2.Download_Chart");
const downloadChartGreen = getPath("2.Download_Chart-Green");
const copyGreen = getPath("2.Copy_Green");
const homeChart = getPath("2.home");
const homeGreenChart = getPath("2.home-green");
const leftArrowChart = getPath("2.left-arrow");
const leftArrowGreenChart = getPath("2.left-arrow-green");
const rightArrowChart = getPath("2.chart-right-arrow");
const rightArrowGreenChart = getPath("2.chart-right-arrow-green");
const rightArrow = getPath("2.right-arrow");
const rightArrowGreen = getPath("2.right-arrow-green");
const muteNotifications = getPath("notification_mute");
const searchBlack = getPath("0.search_black");
const searchGreen = getPath("0.search_green");
const graph_gray = getPath("0.graph_gray");
const handShake = getPath("7.hand-shake");
const defaultInvestorLogo = getPath("6.default-investor-logo");
const csvIcon = getPath("0.csv");
const csvGreenIcon = getPath("0.csv_green");
const pictureIcon = getPath("0.picture");
const pictureGreenIcon = getPath("0.picture_green");
const progressFlag = getPath("2.progress-flag");
const closeRed = getPath("7.close-red");
const adminBlack = getPath("7.admin-black");
const adminGreen = getPath("7.admin-green");
const medalBlack = getPath("2.medal_black");
const taxonomyGreen = getPath("2.taxonomy-green");
const taxonomyBlack = getPath("2.taxonomy-black");
const removeGray = getPath("8.remove");
const removeGreen = getPath("8.remove_green");
const magicWandGray = getPath("8.magic_wand");
const magicWandGreen = getPath("8.magic_wand_green");
const marketResearch = getPath("2.Market_Research");
const marketResearchGreen = getPath("2.Market_Research_Green");
const removeFilterRed = getPath("2.Remove_Red");
const removeFilterGrey = getPath("2.Remove_Grey");
const pdfWhite = getPath("9.pdf_white");
const pdfGreen = getPath("9.pdf_green");
const wordWhite = getPath("10.word_white");
const wordGreen = getPath("10.word_green");
const subTreeIcon = getPath("8.sub-tree");
const generatedByAIIcon = getPath("8.AI-generated");
const doneIcon = getPath("8.Done");
const logInIcon = getPath("11.log_in");
const fileUpload = getPath("9.file-upload");
const closeRoundedCorner = getPath("9.close");
const companyClientIcon = getPath("company-client");
const checkedBoxImage = getPath("1.Checkbox_Checked");
const minusCheckedBoxImage = getPath("1.Minus_Checkbox_Checked");
const emptyContact = getPath("2.empty-contact");

export {
  arrowRight,
  arrowRightGreen,
  arrowLeft,
  passwordChanged,
  arrowLeftGreen,
  magnifierGrey,
  netZeroInsightsLogoHighRes,
  airPlane,
  chevronDown,
  sortAsc,
  sortDesc,
  sortInactive,
  information,
  informationGreen,
  showPassword,
  hidePassword,
  cross,
  notificationBellGreen,
  removeRed,
  validate,
  defaultIcon,
  emptyFolder,
  openLinkGreen,
  menuBlack,
  dashboardBlack,
  dashboardGreen,
  lastSeenEye,
  organizationsBlack,
  organizationsGreen,
  organizationsGrey,
  hearthGreen,
  savedBlack,
  savedGreen,
  lensGreen,
  hearthBlack,
  emailConfirmed,
  moneyBagGreen,
  moneyBagBlack,
  compassGreen,
  compassBlack,
  moneyBagGrey,
  settingsBlack,
  settingsGreen,
  settingsGrey,
  robotGreen,
  addFriendGreen,
  website,
  location,
  linkedInNormal,
  linkedInHover,
  twitterNormal,
  twitterHover,
  facebookNormal,
  facebookHover,
  emailNormal,
  emailHover,
  phoneNormal,
  phoneHover,
  heartFilledRed,
  heartFilledGrey,
  heartFilledWhite,
  heartFilledGreen,
  craneLogo,
  linkedin,
  editGreen,
  editWhite,
  editBlack,
  hearthWhite,
  download,
  downloadHover,
  sortColumns,
  sortColumnsHover,
  dragIcon,
  crossIcon,
  minusIcon,
  plusIcon,
  filterGreen,
  icon404,
  removeTagGreen,
  affinityColored,
  affinityGreen,
  affinityGrey,
  question,
  chevronDownGreen,
  plusIconGreen,
  plusIconGray,
  refreshIcon,
  downloadChart,
  checkedCircle,
  chainIcon,
  chainIconGreen,
  newDownload,
  newDownloadGreen,
  bookmark,
  bookmarkGreen,
  column,
  columnGreen,
  manageColumns,
  manageColumnsGreen,
  addToPlayList,
  addToPlayListGreen,
  copyGreen,
  homeGreenChart,
  homeChart,
  leftArrowChart,
  rightArrowChart,
  leftArrowGreenChart,
  rightArrowGreenChart,
  rightArrow,
  rightArrowGreen,
  downloadChartGreen,
  muteNotifications,
  searchGreen,
  searchBlack,
  graph_gray,
  handShake,
  defaultInvestorLogo,
  csvIcon,
  pictureIcon,
  csvGreenIcon,
  pictureGreenIcon,
  progressFlag,
  closeRed,
  adminBlack,
  adminGreen,
  medalBlack,
  taxonomyGreen,
  taxonomyBlack,
  removeGray,
  removeGreen,
  magicWandGray,
  magicWandGreen,
  marketResearch,
  marketResearchGreen,
  removeFilterRed,
  removeFilterGrey,
  pdfWhite,
  pdfGreen,
  wordWhite,
  wordGreen,
  subTreeIcon,
  generatedByAIIcon,
  doneIcon,
  logInIcon,
  netZeroInsightsLogo,
  fileUpload,
  closeRoundedCorner,
  companyClientIcon,
  checkedBoxImage,
  minusCheckedBoxImage,
  emptyContact,
};
