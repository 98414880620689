import {
  Contact,
  DealType,
  MainFilter,
  SimilarCompaniesFilter,
  PatentOffice,
  TBImpactScoreBucket,
} from "@/shared/types/filters";
import axios from "@/plugins/axios/axios";
import { AxiosResponse } from "axios";
import type {
  COCraneTechnology,
  OrganizationSearchResult,
  TBCraneTechnology,
  StartupSustainabilityMetrics,
  TBERPAnnual,
  SearchLocation,
  LocationsAndRegionsResponseData,
  UpdateCustomColumnModel,
  NewCompanyModel,
  DuplicatedDataModel,
  ContactRoles,
  ContactModel,
  ManageColumnsType,
  HQRegion,
  EmployeesItem,
  CloudinaryResponse,
  TimelinePOJO,
  CompanyFinancials,
  EmployeesGrowthJSON,
  UniversalSearchResult,
  OrganizationNote,
  NewsLabel,
  OrganisationNews,
  CompetitorRequestData,
  FinancialSource,
  EUTaxonomy,
} from "@/shared/types/organization";

import {
  COFundingRoundsPrint,
  Patent,
  PitchLineOption,
  Startup,
  TBValueChain,
  WCColumnMapping,
} from "@/shared/types/common";
import type {
  CustomColumnTypes,
  CustomColumnModel,
  CustomColumnResponse,
  CustomColumnPostResponse,
  CustomColumnsMap,
  AppTableColumn,
} from "@/shared/types/components";

async function checkStartupVisibility(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<{ count: number; results: Startup[] }>> {
  return await axios.post("/checkStartupVisibility", clientId, {
    signal: abortSignal,
  });
}

async function getStartupList(
  mainFilter: MainFilter,
  abortSignal?: AbortSignal,
  callback?: () => unknown,
): Promise<
  AxiosResponse<{
    count: number;
    results: Startup[];
    startupsTotalFunding: number;
  }>
> {
  const response = await axios.post("/getStartupList", mainFilter, {
    signal: abortSignal,
  });

  if (callback) {
    callback();
  }

  return response;
}

async function getSimilarCompanies(
  mainFilter: SimilarCompaniesFilter,
  abortSignal?: AbortSignal,
): Promise<
  AxiosResponse<{
    count: number;
    results: Startup[];
    startupsTotalFunding: number;
    selectedCurrency: string;
  }>
> {
  const response = await axios.post("/competitors", mainFilter, {
    signal: abortSignal,
  });

  return response;
}

async function getStartupByClientID(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Startup>> {
  return await axios.get(`/getStartupByClientID/${clientId}`, {
    signal: abortSignal,
  });
}

function getTbImpactScoreBuckets(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBImpactScoreBucket[]>> {
  return axios.get("/basic/getImpactScoreBucketList", { signal: abortSignal });
}

function getOrganizationSearchResults(
  filter: string,
): Promise<AxiosResponse<OrganizationSearchResult[]>> {
  return axios.get(`/querySearch/${filter}`);
}

function getUniversalSearchResults(
  searchText: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<UniversalSearchResult>> {
  return axios.get(`/universalSearch/${searchText}`, {
    signal: abortSignal,
  });
}

function getOrganizationAdvancedSearchResults(
  filter: string,
): Promise<AxiosResponse<OrganizationSearchResult[]>> {
  return axios.get(`/advancedFilters/querySearch/${filter}`);
}

function getRecentlyAddedOrganizations(
  callback?: () => unknown,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Startup[]>> {
  const response = axios.get("/getRecentlyAggregatedStartups", {
    signal: abortSignal,
  });

  if (callback) {
    callback();
  }

  return response;
}

function getStartup(
  directUrl: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Startup>> {
  return axios.get(`/getStartup/${directUrl}`, { signal: abortSignal });
}

function getFundingRoundsPrintByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<COFundingRoundsPrint[]>> {
  return axios.get(`/fundingRoundsPrints/${clientId}`, { signal: abortSignal });
}

function getFiledPatentsByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<number>> {
  return axios.get(`/getCompanyFiledPatents/${clientId}`, {
    signal: abortSignal,
  });
}

function getPatentsCountByClientIdAndStatus(
  clientId: number,
  status: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<number>> {
  return axios.get(`/getCompanyPatentsCountByStatus/${clientId}/${status}`, {
    signal: abortSignal,
  });
}

function getLastPatentActivityByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<string>> {
  return axios.get(`/getCompanyLastPatentActivity/${clientId}`, {
    signal: abortSignal,
  });
}

function getPatentOffices(): Promise<AxiosResponse<PatentOffice[]>> {
  return axios.get("/patentOffices");
}

function getCoCraneTechnologyByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<COCraneTechnology>> {
  return axios.get(`/craneTechnologies/${clientId}`, {
    signal: abortSignal,
  });
}

function getCustomerCustomTags(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<number[]>> {
  return axios.get(`/getCustomerCustomTags`, {
    signal: abortSignal,
  });
}

function getValueChains(
  tagId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBValueChain[]>> {
  return axios.get(`/value-chains/${tagId}`, {
    signal: abortSignal,
  });
}

function getTbCraneTechnologies(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBCraneTechnology[]>> {
  return axios.get("/craneTechnologies", {
    signal: abortSignal,
  });
}

function getTbErpAnnualTechnologiesByCraneId(
  craneId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TBERPAnnual[]>> {
  return axios.get(`/erp-annual-technologies/${craneId}`, {
    signal: abortSignal,
  });
}

function getCompanySustainabilityMetrics(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<StartupSustainabilityMetrics>> {
  return axios.get("/getCompanySustainabilityMetrics", { signal: abortSignal });
}

function getCompanyContactsByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Contact[]>> {
  return axios.get(`/contacts/${clientId}`, { signal: abortSignal });
}

function getCompanyFinacialsByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CompanyFinancials[]>> {
  return axios.get(`/detailsPage/financials/${clientId}`, {
    signal: abortSignal,
  });
}

function getContactRoles(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<ContactRoles[]>> {
  return axios.get("/contacts/contactRoles/", { signal: abortSignal });
}

function getFilteredContacts(
  model: ContactModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Contact[]>> {
  return axios.post("/contacts/company", model, { signal: abortSignal });
}

function getPatentsByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<Patent[]>> {
  return axios.get(`/patents/${clientId}`, { signal: abortSignal });
}

function getPitchLinesByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<PitchLineOption[]>> {
  return axios.get(`/pitchLines/${clientId}`, { signal: abortSignal });
}

function getStartupCount(
  mainFilter: MainFilter,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<{ count: number }>> {
  return axios.post("/getStartupCount", mainFilter, { signal: abortSignal });
}

function getSearchLocations(
  location: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<SearchLocation[]>> {
  return axios.get(`/searchLocation/location/${location}`, {
    signal: abortSignal,
  });
}

function getSearchLocationsAndRegions(
  location: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<LocationsAndRegionsResponseData>> {
  return axios.get(`/searchLocation/${location}`, {
    signal: abortSignal,
  });
}

function getSearchLocationByID(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<SearchLocation>> {
  return axios.get(`/searchLocation/locationByID/${id}`, {
    signal: abortSignal,
  });
}

function getHQRegions(
  region: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<HQRegion[]>> {
  return axios.get(`/searchLocation/region/${region}`, { signal: abortSignal });
}

function addNote(
  organizationNote: OrganizationNote,
): Promise<AxiosResponse<number>> {
  return axios.post("/note/company", organizationNote);
}

function removeNote(clientID: number): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/note/company/${clientID}`);
}

function downloadExcel(
  mainFilter: MainFilter,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.post("/downloadExcel", mainFilter, {
    signal: abortSignal,
    responseType: "blob",
  });
}

function downloadOrganisations(
  mainFilter: MainFilter,
  contactTypes: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.post("/export/downloadOrganisations", {
    mainFilter,
    contactTypes,
    signal: abortSignal,
  });
}

function getCustomColumnTypes(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnTypes[]>> {
  return axios.get("/customColumn/types", {
    signal: abortSignal,
  });
}

function getColumnNames(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnResponse>> {
  return axios.get("/customColumn", {
    signal: abortSignal,
  });
}

function getColumnsMap(
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnsMap>> {
  return axios.get("/customColumn/values", {
    signal: abortSignal,
  });
}

function editCustomColumn(
  id: number,
  value: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<WCColumnMapping>> {
  return axios.put(
    `/customColumn/values/${id}`,
    {
      value,
    },
    {
      signal: abortSignal,
    },
  );
}

function createCustomColumn(
  data: CustomColumnModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnPostResponse>> {
  return axios.post("/customColumn", data, {
    signal: abortSignal,
  });
}

function removeCustomColumn(
  columnId: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnPostResponse>> {
  return axios.delete(`/customColumn/${columnId}`, {
    signal: abortSignal,
  });
}

function updateCustomColumn(
  data: UpdateCustomColumnModel,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<CustomColumnPostResponse>> {
  return axios.put(
    `/customColumn/${data.columnId}`,
    { name: data.newCustomColumnName },
    {
      signal: abortSignal,
    },
  );
}

function addRequestReview(
  clientID: number,
  note: string,
  dataCheckboxIDs: number[],
): Promise<AxiosResponse> {
  return axios.post(`/review/companyRequestReview`, {
    clientID,
    note,
    dataCheckboxIDs,
  });
}

function addNewCompany(data: NewCompanyModel): Promise<AxiosResponse<Startup>> {
  return axios.post(`/insertNewCompany`, data);
}

function setCompanyNotStartup(
  clientId: number,
  option: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.put(
    `/edit/notStartup/${clientId}`,
    { paramString: option },
    {
      signal: abortSignal,
    },
  );
}

function setCompanyNotGreen(clientId: number): Promise<AxiosResponse> {
  return axios.put(`/edit/notGreen/${clientId}`);
}

function setCompanyInactive(clientId: number): Promise<AxiosResponse> {
  return axios.put(`/edit/notActive/${clientId}`);
}

function setCompanyDuplicated(
  data: DuplicatedDataModel,
): Promise<AxiosResponse> {
  return axios.put(`/edit/duplicated/${data.keepId}`, {
    paramList: data.removeIds,
  });
}

function confirmCompanyInfo(
  clientId: number,
  message: string,
): Promise<AxiosResponse> {
  return axios.put(`/review/companyInfo/${clientId}`, { message });
}

function confirmRoundInfo(
  clientId: number,
  message: string,
): Promise<AxiosResponse> {
  return axios.put(`/review/fundingRoundInfo/${clientId}`, {
    message,
  });
}

async function saveImageToCloudinary(
  file: File,
  folder: "Startups" | "Investors" | "report_thumbnails" | "Contacts",
): Promise<CloudinaryResponse> {
  const formData: FormData = new FormData();

  formData.append("file", file);
  formData.append("upload_preset", "aetzifpf");
  formData.append("folder", folder);

  return await fetch("https://api.cloudinary.com/v1_1/eutop-1/image/upload", {
    method: "POST",
    body: formData,
  }).then((response) => {
    return response.json();
  });
}

function updateOrganizationLogo(
  clientId: number,
  file: File,
): Promise<AxiosResponse> {
  return axios.put(
    `/edit/logo/${clientId}`,
    { file },
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
}

function updateOrganizationName(
  clientId: number,
  newName: string,
): Promise<AxiosResponse> {
  return axios.put(`/edit/name/${clientId}`, { paramString: newName });
}

function updateOrganizationDescription(
  clientId: number,
  description: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.put(
    `/edit/description/${clientId}`,
    { paramString: description },
    {
      signal: abortSignal,
    },
  );
}

function updateOrganizationWebsite(
  clientId: number,
  newWebsite: string,
): Promise<AxiosResponse> {
  return axios.put(`/edit/website/${clientId}`, { paramString: newWebsite });
}

function updateOrganizationLocation(
  clientId: number,
  locationId: number,
): Promise<AxiosResponse> {
  return axios.put(`/edit/location/${clientId}`, { paramNumber: locationId });
}

function updateOrganizationEmployees(
  clientId: number,
  employeesNumber: number,
): Promise<AxiosResponse> {
  return axios.put(`/edit/employees/${clientId}`, {
    paramNumber: employeesNumber,
  });
}

function updateOrganizationEmployeesSize(
  clientId: number,
  sizeId: number,
): Promise<AxiosResponse> {
  return axios.put(`/edit/employees/size/${clientId}`, { paramNumber: sizeId });
}

function updateOrganizationEmployeesGrowthJSON(
  data: EmployeesGrowthJSON,
): Promise<AxiosResponse> {
  return axios.post("/editEmployeesGrowthJSON", data);
}

function updateOrganizationLinkedin(
  clientId: number,
  newLinkedinUrl: string,
): Promise<AxiosResponse> {
  return axios.put(`/edit/linkedin/${clientId}`, {
    paramString: newLinkedinUrl,
  });
}

function updateOrganizationFoundedYear(
  clientId: number,
  foundedYear: number,
): Promise<AxiosResponse> {
  return axios.put(`/edit/foundedYear/${clientId}`, {
    paramNumber: foundedYear,
  });
}

function updateOrganizationAlternativeNames(
  clientId: number,
  alternativeNames: string[],
): Promise<AxiosResponse> {
  return axios.put(`/edit/alternativeNames/${clientId}`, {
    paramList: alternativeNames,
  });
}

function updateOrganizationLegalNames(
  clientId: number,
  legalNames: string[],
): Promise<AxiosResponse> {
  return axios.put(`/edit/legalNames/${clientId}`, { paramList: legalNames });
}

function updateOrganizationTags(
  clientId: number,
  tagIds: number[],
  abortSignal?: AbortSignal,
): Promise<AxiosResponse> {
  return axios.put(
    `/edit/tags/${clientId}`,
    { paramList: tagIds },
    {
      signal: abortSignal,
    },
  );
}

function updateOrganizationPIFrameworks(
  clientId: number,
  piFrameworks: number[],
): Promise<AxiosResponse<string>> {
  return axios.put(`/edit/piframeworks/${clientId}`, {
    paramList: piFrameworks,
  });
}

function saveCustomColumns(
  columnsType: ManageColumnsType,
  columns: AppTableColumn[],
): Promise<AxiosResponse<AppTableColumn[]>> {
  return axios.post("/customColumn/order", {
    columnsType,
    columns,
  });
}

function getCustomColumns(
  columnsType: ManageColumnsType,
): Promise<AxiosResponse<AppTableColumn[]>> {
  return axios.get(`/customColumn/order/${columnsType}`);
}

function getDealTypes(): Promise<AxiosResponse<DealType[]>> {
  return axios.get("/dealTypes");
}

function getEmployeesCount(
  mainFilter: MainFilter,
): Promise<AxiosResponse<EmployeesItem[]>> {
  return axios.post("/dashboard/employees", mainFilter);
}

function getTimelineByClientId(
  clientId: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<TimelinePOJO>> {
  return axios.get(`/timeline/${clientId}`, {
    signal: abortSignal,
  });
}

function acceptUserOrganizationSubmission(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<string>> {
  return axios.get(`/review/accept/${id}`, {
    signal: abortSignal,
  });
}

function rejectUserOrganizationSubmission(
  id: number,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<boolean>> {
  return axios.get(`/review/reject/${id}`, {
    signal: abortSignal,
  });
}

function downloadOrganisationProfile(
  format: string,
  clientID: number,
): Promise<AxiosResponse<string>> {
  return axios.post("/export/organisationProfile", {
    format,
    clientID,
  });
}

function getNewsLabels(): Promise<AxiosResponse<NewsLabel[]>> {
  return axios.get(`/label`);
}

function getCompanyNews(
  clientId: number,
  signal?: AbortSignal,
): Promise<AxiosResponse<OrganisationNews[]>> {
  return axios.get(`/companyNews/${clientId}`, {
    signal,
  });
}

function addCompanyNews(
  companyNews: OrganisationNews,
): Promise<AxiosResponse<OrganisationNews>> {
  return axios.post("/companyNews", companyNews);
}

function updateCompanyNews(
  companyNews: OrganisationNews,
): Promise<AxiosResponse<OrganisationNews>> {
  return axios.put(`/companyNews/${companyNews.id}`, companyNews);
}

function deleteCompanyNews(
  companyNewsId: number,
): Promise<AxiosResponse<boolean>> {
  return axios.delete(`/companyNews/${companyNewsId}`);
}

function getSimiliarCompaniesSearchResult(
  clientId: number,
  filter: string,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<OrganizationSearchResult[]>> {
  return axios.get(`/competitors/search/${clientId}?searchText=${filter}`, {
    signal: abortSignal,
  });
}

function addCompetitor(
  newCompetitor: CompetitorRequestData,
  abortSignal?: AbortSignal,
): Promise<AxiosResponse<{ count: number; results: Startup[] }>> {
  return axios.post("/competitors/add", newCompetitor, {
    signal: abortSignal,
  });
}

function deleteCompetitors(
  data: CompetitorRequestData,
): Promise<AxiosResponse<{ count: number; results: Startup[] }>> {
  return axios.delete("/competitors", { data });
}

function getFinancialSources(): Promise<AxiosResponse<FinancialSource[]>> {
  return axios.get("/financials/sources");
}

function getEuTaxonomiesByClientId(
  clientId: number,
): Promise<AxiosResponse<EUTaxonomy[]>> {
  return axios.get(`/taxonomy/eu/${clientId}`);
}

export default {
  getStartupList,
  getSimilarCompanies,
  getTbImpactScoreBuckets,
  getOrganizationSearchResults,
  getStartup,
  getFundingRoundsPrintByClientId,
  getRecentlyAddedOrganizations,
  getFiledPatentsByClientId,
  getPatentsCountByClientIdAndStatus,
  getLastPatentActivityByClientId,
  getCustomerCustomTags,
  getValueChains,
  getColumnsMap,
  getCoCraneTechnologyByClientId,
  getTbCraneTechnologies,
  getTbErpAnnualTechnologiesByCraneId,
  getCompanySustainabilityMetrics,
  getCompanyContactsByClientId,
  getCompanyFinacialsByClientId,
  getPatentsByClientId,
  getStartupCount,
  addNote,
  removeNote,
  downloadExcel,
  downloadOrganisations,
  getCustomColumnTypes,
  createCustomColumn,
  getColumnNames,
  editCustomColumn,
  getSearchLocations,
  getSearchLocationsAndRegions,
  getSearchLocationByID,
  removeCustomColumn,
  updateCustomColumn,
  checkStartupVisibility,
  getStartupByClientID,
  addRequestReview,
  setCompanyNotStartup,
  setCompanyNotGreen,
  setCompanyInactive,
  getPatentOffices,
  addNewCompany,
  getPitchLinesByClientId,
  setCompanyDuplicated,
  confirmCompanyInfo,
  confirmRoundInfo,
  saveImageToCloudinary,
  updateOrganizationLogo,
  updateOrganizationName,
  updateOrganizationWebsite,
  updateOrganizationLinkedin,
  updateOrganizationDescription,
  updateOrganizationFoundedYear,
  updateOrganizationLocation,
  updateOrganizationEmployees,
  updateOrganizationEmployeesSize,
  updateOrganizationAlternativeNames,
  updateOrganizationLegalNames,
  updateOrganizationTags,
  getContactRoles,
  getFilteredContacts,
  saveCustomColumns,
  getCustomColumns,
  getHQRegions,
  getDealTypes,
  getEmployeesCount,
  getOrganizationAdvancedSearchResults,
  getTimelineByClientId,
  updateOrganizationEmployeesGrowthJSON,
  acceptUserOrganizationSubmission,
  rejectUserOrganizationSubmission,
  updateOrganizationPIFrameworks,
  getUniversalSearchResults,
  downloadOrganisationProfile,
  addCompetitor,
  getSimiliarCompaniesSearchResult,
  getNewsLabels,
  getCompanyNews,
  addCompanyNews,
  updateCompanyNews,
  deleteCompanyNews,
  deleteCompetitors,
  getFinancialSources,
  getEuTaxonomiesByClientId,
};
