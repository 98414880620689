<template>
  <component :is="linkComponent" v-bind="$attrs" :target="targetValue">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { computed, useAttrs } from "vue";
import { isExternalLink } from "@/shared/helpers/isExternalLink/isExternalLink";

const props = withDefaults(
  defineProps<{
    linkComponent?: "a" | "router-link";
    strictTarget?: "_blank" | "_self";
  }>(),
  {
    linkComponent: "a",
  },
);

const attrs = useAttrs();

const { getNewTabEnabled } = storeToRefs(useWebCustomerStore());

const targetValue = computed(() => {
  if (props.strictTarget) return props.strictTarget;

  if (isExternalLink((attrs.href || attrs.to || "") as string)) {
    return "_blank";
  }

  return getNewTabEnabled.value && (attrs.to as string)?.slice(0, 1) !== "#"
    ? "_blank"
    : "_self";
});
</script>
