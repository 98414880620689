import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import pinia from "@/stores";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import { WebCustomerRole } from "@/shared/types/common";
import { searchPageRoutes } from "@/router/searchPageRoutes";
import adminService from "@/shared/services/adminService/adminService";
import { defaultServerErrorMessage } from "@/shared/constants/errors";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/example",
    meta: {
      title: "Example view",
      layout: "claim-profile",
    },
    name: "example",
    component: () => import("@/views/app/AppExampleView/AppExampleView.vue"),
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/user/forgot-password/:token",
    component: () =>
      import("@/views/RecoverPasswordView/RecoverPasswordView.vue"),
    meta: {
      layout: "unauthorized",
    },
  },
  {
    path: "/review/markInvestor/:id",
    name: "ReviewMarkInvestor",
    component: () => ({}),
    beforeEnter: async (to, from, next) => {
      try {
        const { data: path } = await adminService.reviewMarkInvestor(
          Number(to.params.id),
        );

        next({ path });
      } catch (_) {
        const { notify } = useNotificationsStore();

        notify(defaultServerErrorMessage, "danger");
        next({ name: "Dashboard" });
      }
    },
  },
  {
    path: "/reset-password",
    component: () => import("@/views/ResetPasswordView/ResetPasswordView.vue"),
    meta: {
      title: "Reset Password | Net Zero Insights",
      layout: "unauthorized",
    },
  },
  {
    path: "/change-password/:token",
    component: () =>
      import("@/views/ChangePasswordView/ChangePasswordView.vue"),
    name: "ChangeExpiredPassword",
    meta: {
      title: "Change Password | Net Zero Insights",
      layout: "unauthorized",
    },
  },
  {
    path: "/user/confirm-email/:id",
    component: () => import("@/views/ConfirmEmailView/ConfirmEmailView.vue"),
    meta: {
      title: "Confirm Email | Net Zero Insights",
      layout: "unauthorized",
    },
  },
  {
    path: "/login",
    name: "sign-in",
    component: () => import("@/views/SignInView/SignInView.vue"),
    meta: {
      title: " Net Zero Insights | Green startup database",
      layout: "unauthorized",
    },
  },
  {
    path: "/logout",
    name: "Sign out",
    component: () => ({}),
    beforeEnter: async () => {
      const webCustomerStore = useWebCustomerStore(pinia);

      await webCustomerStore.logoutWebCustomer();
      await router.push("/login");
    },
  },
  {
    path: "/searches",
    name: "MySearches",
    component: () => import("@/views/MySearchesView/MySearchesView.vue"),
    meta: {
      title: "My Searches | Net Zero Insights",
    },
  },
  {
    path: "/user/profile/:id?",
    name: "Profile",
    component: () => import("@/views/ProfileView/ProfileView.vue"),
    meta: {
      title: "Profile | Net Zero Insights",
    },
  },
  {
    path: "/organisation/:id",
    name: "OrganisationDetails",
    component: () =>
      import("@/views/OrganizationDetailsView/OrganizationDetailsView.vue"),
    meta: {
      title: "Loading...",
    },
  },
  {
    path: "/organization/:id",
    name: "OrganizationDetails",
    component: () =>
      import("@/views/OrganizationDetailsView/OrganizationDetailsView.vue"),
    meta: {
      title: "Loading...",
    },
  },
  {
    path: "/organization/:id/edit",
    name: "EditOrganizationDetails",
    component: () =>
      import(
        "@/views/EditOrganizationDetailsView/EditOrganizationDetailsView.vue"
      ),
    meta: {
      title: "Loading...",
    },
    beforeEnter: async () => {
      const { isUserAdmin } = useWebCustomerStore(pinia);

      if (!isUserAdmin) {
        router.replace("/organizations");
      }
    },
  },
  {
    path: "/investor/:id",
    name: "InvestorDetails",
    component: () =>
      import("@/views/InvestorDetailsView/InvestorDetailsView.vue"),
    meta: {
      title: "Loading...",
    },
  },
  {
    path: "/investor/:id/edit",
    name: "EditInvestorDetails",
    component: () =>
      import("@/views/EditInvestorDetailsView/EditInvestorDetailsView.vue"),
    meta: {
      title: "Loading...",
    },
    beforeEnter: async () => {
      const { isUserAdmin } = useWebCustomerStore(pinia);

      if (!isUserAdmin) {
        router.replace("/search");
      }
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/DashboardView/DashboardView.vue"),
    meta: {
      title: "Home | Net Zero Insights",
    },
  },
  {
    path: "/lists",
    name: "Portfolios",
    component: () => import("@/views/PortfoliosView/PortfoliosView.vue"),
    meta: {
      title: "Lists | Net Zero Insights",
    },
    children: [
      {
        path: "organisations",
        name: "OrganisationPortfolios",
        component: () => import("@/views/PortfoliosView/PortfoliosView.vue"),
        meta: {
          title: "Lists | Net Zero Insights",
        },
      },
      {
        path: "investors",
        name: "InvestorPortfolios",
        component: () => import("@/views/PortfoliosView/PortfoliosView.vue"),
        meta: {
          title: "Lists | Net Zero Insights",
        },
      },
    ],
  },

  {
    path: "/maximum-number-of-companies",
    name: "MaximumNumberOfCompaniesView",
    component: () =>
      import(
        "@/views/MaximumNumberOfCompaniesView/MaximumNumberOfCompaniesView.vue"
      ),
    meta: {
      title: "Maximum number of companies | Net Zero Insights",
      layout: "free-trial",
    },
  },
  {
    path: "/admin-panel/:tab?",
    name: "AdminPanel",
    component: () => import("@/views/AdminPanelView/AdminPanelView.vue"),
    meta: {
      title: "Admin panel | Net Zero Insights",
    },
    beforeEnter: async () => {
      const { isUserAdmin } = useWebCustomerStore(pinia);

      if (!isUserAdmin) {
        router.replace("/");
      }
    },
  },
  {
    path: "/claim-profile",
    name: "ClaimProfile",
    children: [
      {
        path: "",
        name: "ClaimProfileInbound",
        component: () =>
          import("@/views/ClaimProfileInboundView/ClaimProfileInboundView.vue"),
        meta: {
          layout: "unauthorized",
        },
      },
      {
        path: ":token",
        name: "ClaimProfileOutbound",
        component: () =>
          import(
            "@/views/ClaimProfileOutboundView/ClaimProfileOutboundView.vue"
          ),
      },
      {
        path: ":token/who-are-you",
        name: "ClaimProfileWhoAreYou",
        component: () =>
          import(
            "@/views/ClaimProfileWhoAreYouView/ClaimProfileWhoAreYouView.vue"
          ),
      },
      {
        path: ":token/:path/:version?",
        name: "ClaimProfileForm",
        component: () =>
          import("@/views/ClaimProfileFormView/ClaimProfileFormView.vue"),
      },
    ],
    meta: {
      title: "Claim Profile | Net Zero Insights",
      layout: "claim-profile",
    },
  },
  {
    path: "/taxonomy/:id*",
    name: "Taxonomy",
    component: () => import("@/views/TaxonomyView/TaxonomyView.vue"),
    meta: {
      title: "Taxonomy | Net Zero Insights",
    },
  },
  {
    path: "/market-research",
    name: "market-research",
    component: () =>
      import("@/views/MarketResearchView/MarketResearchView.vue"),
    meta: {
      title: "Market Research | Net Zero Insights",
    },
  },
  {
    path: "/market-research/:label/:tab?",
    name: "DeepDiveDetailsView",
    component: () =>
      import("@/views/DeepDiveDetailsView/DeepDiveDetailsView.vue"),
    meta: {
      title: "Loading...",
    },
  },
  {
    path: "/review/accept/:id",
    name: "AcceptUserSubmission",
    component: () =>
      import(
        "@/views/AcceptOrganizationSubmissionView/AcceptOrganizationSubmissionView.vue"
      ),
    meta: {
      title: "Accept user submission | Net Zero Insights",
    },
    beforeEnter: async () => {
      const { isUserAdmin } = useWebCustomerStore(pinia);

      if (!isUserAdmin) {
        router.replace("/");
      }
    },
  },
  {
    path: "/review/reject/:id",
    name: "RejectUserSubmission",
    component: () =>
      import(
        "@/views/RejectOrganizationSubmissionView/RejectOrganizationSubmissionView.vue"
      ),
    meta: {
      title: "Reject user submission | Net Zero Insights",
    },
    beforeEnter: async () => {
      const { isUserAdmin } = useWebCustomerStore(pinia);

      if (!isUserAdmin) {
        router.replace("/");
      }
    },
  },
  {
    path: "/search-results/:tab?/:keyword?",
    name: "SearchResultsView",
    component: () => import("@/views/SearchResultsView/SearchResultsView.vue"),
    meta: {
      title: "Search Results | Net Zero Insights",
    },
  },
  ...searchPageRoutes,
  {
    path: "/:pathMatch(.*)*",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound/PageNotFound.vue"),
    meta: {
      title: "Page Not Found",
    },
    beforeEnter: (to) => {
      if (to.path !== "/not-found") router.replace("/not-found");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.path === from.path) return next();

  document.title =
    (to.meta.title as string | undefined) ||
    (process.env.VUE_APP_DEFAULT_TITLE as string);

  const webCustomer = useWebCustomerStore();

  try {
    if (!webCustomer.isAuth) await webCustomer.getLoggedWebCustomer();

    if (to.meta.layout === "unauthorized" && webCustomer.isAuth) {
      next({ name: "Dashboard" });
    } else if (
      webCustomer.isAuth &&
      webCustomer.webCustomer.role === WebCustomerRole.STARTUP &&
      ["/dashboard", "/user/profile", "/claim-profile", "/logout"].every(
        (path) => !to.path.startsWith(path),
      )
    ) {
      next({ name: "Dashboard", query: { role: WebCustomerRole.STARTUP } });
    } else {
      next();
    }
  } catch (error) {
    if (to.meta.layout === "unauthorized") next();
    else next({ name: "sign-in" });
  }
});

export default router;
